import logo from './logo.svg';
import './App.css';
import {useEffect, useState} from "react";

function App() {

    const [data, setData] = useState({title: "", subtitle: ""})


    useEffect(() => {

        fetch(`https://bfmag.devops-guy.com/data/data.json`,
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((res) => {
                res.json().then((response) => {
                    setData(() => {
                        return (
                            {
                                title: response.title,
                                content: response.content
                            }
                        )
                    })
                }).catch(e => {
                    console.log(e)
                })
            }).catch(e => {
            console.log(e)
        });
    }, [setData])


    useEffect(() => {
        // console.log("do some crap here", data)
    }, [data])

    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo"/>
                <h1>{data.title}</h1>
                <h4>{data.content}</h4>
                <p>
                    Edit <code>src/App.js</code> and save to reload.
                </p>
                <a
                    className="App-link"
                    href="https://reactjs.org"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Learn React
                </a>
            </header>
        </div>
    );
}

export default App;
